'use client';

import {useState} from 'react';
import Image from "next/image";
import { PieceProps } from "@/types/piece";
// import CustomButton from "./CustomButton";
// import {calculateCarRent,generateCarImageUrl} from "../utils";
import PieceDetail from "./PieceDetail";

const PieceCard = ({ userId, piece, handleTogglePublished, handleDelete }: {userId: string, piece: PieceProps, 
    handleTogglePublished: (piece: PieceProps) => void, 
    handleDelete: (piece: PieceProps) => void}) => {
    const {title, url, published, description} = piece;
    // 获取缩略图
    const key = (new URL(url)).pathname.slice(1);

    console.log(url);
    const resizeUrl = `https://s3t.fluidics.cc/${key}`;
    console.log(resizeUrl);
    // const carRent = calculateCarRent(city_mpg,year);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="car-card group" key={piece.id}>
            <div className="car-card__content">
                <h2 className="car-card__content-title w-48 truncate overflow-hidden text-ellipsi max-h-40">{title ?? ''}</h2>
            </div>
{/* 
            <p className="flex mt-6 text-[32px] font-extrabold">
                <span className="self-start text-[14px] font-semibold">$</span>
                {carRent}
                <span className="self-end text-[14px] font-medium">
                    /day
                </span>
            </p> */}


            <div className="relative w-full h-40 my-3 object-contain">
                <Image src={ resizeUrl } alt="Piece Image" 
                fill 
                sizes="(max-width: 768px) 100vw, 500px" 
                onClick={() => setIsOpen(true)}
                loading='lazy'
                className="object-contain"/>
            </div>

            <div className="relative flex w-full mt-2">
                {/* <div className="flex group-hover:invisible w-full justify-between text-gray">
                    <div className="flex flex-col justify-center items-center gap-2">
                        <Image src="/steering-wheel.svg" width={20} height={20} alt="Steering wheel"/>
                        <p className="text-[14px]">{transmission === 'a' ? 'Automatic' : 'Manual'}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                        <Image src="/tire.svg" width={20} height={20} alt="Tire"/>
                        <p className="text-[14px]">{drive.toUpperCase()}</p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                        <Image src="/gas.svg" width={20} height={20} alt="Gas"/>
                        <p className="text-[14px]">{city_mpg} MPG</p>
                    </div>
                </div> */}

                {/* <div className="car-card__btn-container">
                    <CustomButton
                        title="View More"
                        containerStyles="w-full py-[16px] rounded-full bg-primary-blue ring-offset-0 ring"
                        textStyles="text-white text-[14px] leading-[17px] font-bold"
                        rightIcon="/right-arrow.svg"
                        handleClick={() => setIsOpen(true)}
                    />
                </div> */}
            </div>

            <PieceDetail userId={userId} piece={piece} isOpen={isOpen} closeModal={() => setIsOpen(false)} 
            handleTogglePublished={ handleTogglePublished } handleDelete={ handleDelete } />
        </div>
    );
};

export default PieceCard;
