'use client';
// components/ThemeToggle.js
// import { Eye, EyeOff } from 'lucide-react';

const PublishToggle = ({isChecked, handleToggle}: {isChecked: boolean, handleToggle: () => void}) => {

  return (
    <div className="relative inline-flex items-center cursor-pointer select-none" onClick={handleToggle}>
		<p className="block text-sm font-bold mr-2">Published</p>
      <input type="checkbox" className="sr-only" checked={isChecked} readOnly />
      <div className={`w-10 h-6 rounded-full relative shadow-md ${isChecked ? 'bg-blue-500' : 'bg-gray-300'}`}>
        <div className={`absolute top-0 left-0 w-6 h-6 rounded-full bg-white transition-transform duration-300 ${isChecked ? 'translate-x-4' : ''}`} />
      </div>
    </div>
  );
}

export default PublishToggle;