let globalCanvas: HTMLCanvasElement & Node & any;
let pixelRatio = globalThis.devicePixelRatio;
// canvas初始化
export const createCanvas = (width: number, height: number, backgroundColor: string = '#fff') => {
  globalCanvas = document.createElement('canvas');
  globalCanvas.width = width;
  globalCanvas.height = height;
  globalCanvas.style.width = width / pixelRatio + 'px';
  globalCanvas.style.height = height / pixelRatio + 'px';
  globalCanvas.style.display = 'none';
  const ctx = globalCanvas.getContext('2d');
  ctx.beginPath();

  // 创建线性渐变
  const gradient = ctx.createLinearGradient(0, 0, width, height);
  gradient.addColorStop(0, '#9c0000');
  gradient.addColorStop(1, 'blue');
  // 设置填充样式
  ctx.fillStyle = gradient;
  
  // ctx.fillStyle = backgroundColor;

  ctx.fillRect(0, 0, width, height);
  return globalCanvas;
};

// 转为base64，绘制图片
export const draw = (width: number, height: number) => {
  document.body.append(globalCanvas);
  const dataURL = globalCanvas.toDataURL();
  // document.body.remove(globalCanvas);
  return dataURL;
};

export { globalCanvas, pixelRatio };
