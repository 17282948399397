/**
 * resizeImage
 */

export function resizeImage(image: HTMLImageElement | HTMLCanvasElement, { width, height }: { width: number; height: number; }) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = height;

  // 2-step processing: https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly

  const oc = document.createElement('canvas');
  const octx = oc.getContext('2d');

  oc.width = (image.width - width) / 2;
  oc.height = (oc.width / image.width) * image.height;

  octx?.drawImage(image, 0, 0, oc.width, oc.height);

  ctx?.drawImage(oc, 0, 0, width, height);

  const dataurl = canvas.toDataURL('image/webp');

  return dataurl;
}

/**
 * readImage
 */

interface ReadImageReturn {
  img: HTMLImageElement;
  data: string | ArrayBuffer | null;
}

export function readImage(file: File): Promise<ReadImageReturn> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader;

    reader.onload = function() {
      const img = new Image;

      img.onerror = function() {
        reject(null);
      }

      img.onload = function() {
        resolve({
          data: reader.result,
          img
        });
      };

      img.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  })  
}

/**
 * getImageFormatFromType
 */

const formatsMap: Record<string, string> = {
  avif: 'AVIF',
  jpg: 'JPG',
  jpeg: 'JPG',
  jxl: 'JXL',
  png: 'PNG',
  webp: 'WebP',
}

export function getImageFormatFromType(type: string, formatted = false) {
  const split = type.split('/');
  const format = split[split.length - 1];

  if ( formatted ) return formatsMap[format] || format;

  if ( ['jpeg', 'jpg'].includes(format) ) {
    return 'jpg';
  }

  return format;
}

// 小红书的尺寸
const XHS_IMAGE_SIZES = [
  { width: 900, height: 1200 },
  { width: 1080, height: 1080 },
  { width: 1200, height: 900 },
];

const xPadding = 16;

export function getBestCanvasSize(imageWidth: number, imageHeight: number) {
  // 计算图片的宽高比
  const aspectRatio = imageWidth / imageHeight;

  // 遍历所有规格，找到最接近图片宽高比的规格
  let bestSize: { [key: string]: any } = XHS_IMAGE_SIZES[0];
  let minDifference = Math.abs(aspectRatio - (bestSize.width / bestSize.height));
  for (let i = 1; i < XHS_IMAGE_SIZES.length; i++) {
    const currentSize = XHS_IMAGE_SIZES[i];
    const currentDifference = Math.abs(aspectRatio - (currentSize.width / currentSize.height));
    if (currentDifference < minDifference) {
      bestSize = currentSize;
      minDifference = currentDifference;
    }
  }

  // 检查图片是否小于选中的规格
  if (imageWidth < bestSize.width || imageHeight < bestSize.height) {
    return { width: imageWidth + xPadding, height: imageHeight + xPadding, imgSize: {width: imageWidth, height: imageHeight} }; // 使用原始尺寸
  } 

  // 保持宽度不变，计算图片需要缩放的高度
  let newImageWidth = bestSize.width - xPadding;
  let newImageHeight = imageHeight * newImageWidth / imageWidth;
  bestSize.imgSize = {width: newImageWidth, height: newImageHeight} ;

  return { width: newImageWidth + xPadding, height: newImageHeight + xPadding, imgSize: {width: newImageWidth, height: newImageHeight} };
  
  // return bestSize;
}