"use client";

import {useState} from 'react';
import {Fragment} from "react";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {Dialog,Transition, TransitionChild, DialogPanel } from "@headlessui/react";
import Image from "next/image";
import { PieceProps } from "@/types/piece";
import { CopyIcon, DownloadIcon, X, LoaderCircle } from "lucide-react";
import { downloadUrl, copyFromUrl } from '@/lib/util';
import PublishToggle from '@/components/PublishToggle';
import DeleteButton from '@/components/DeleteButton';
import { toast } from 'sonner';
import { togglePiece } from '@/app/(admin)/x/apis/pieces';

interface PieceDetailProps {
    userId: string;
    isOpen: boolean;
    closeModal: () => void;
    piece: PieceProps;
    handleTogglePublished: (piece: PieceProps) => void;
    handleDelete: (piece: PieceProps) => void;
}

const PieceDetail = ({userId, isOpen, closeModal, piece, handleTogglePublished, handleDelete}: PieceDetailProps) => {
    const [isCopying, setIsCopying] = useState(false);

    // const [showDescription, setShowDescription] = useState(true);
    const [imgWidthClassName, setImgWidthClassName] = useState('');

    const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
      if (e.currentTarget.naturalWidth > 300) {
        setImgWidthClassName('w-full');
      }
    };

    async function handleCopyFromUrl(url: string) {
        if (isCopying) return;
        
        setIsCopying(true);
        try {
            await copyFromUrl(url);
            toast.info('Image copied');
        } catch(e) {
            console.log(`Failed to copy image to clipboard`, e);
            // toast.error('Something went wrong, try again!');
            let message;
            if (e instanceof Error) message = e.message;
            else message = String(e);
            toast.error(message || 'Something went wrong, try again!');
        }
        setIsCopying(false);
      };

      async function handleDownloadUrl(url: string) {
        await downloadUrl(url, 'file', {downloadBlob: true})
      };
      
      const mutation = useMutation({
        mutationFn: async ({ id, published }: { id: string, published: boolean }) => {
          return await togglePiece({id, published});
        },
        onSuccess: () => {
            // 注意 togglePiece 函数不要返回 pieceData，网页会报错：
            // Error: Only plain objects, and a few built-ins, can be passed to Client Components from Server Components. Classes or null prototypes are not supported.
        //   queryClient.invalidateQueries({
        //     queryKey: ['shortUrls'],
        //   })
        },
      })

      
      async function _handleTogglePublished() {
        // console.log(handlePublishedToggle);
        // await mutation.mutateAsync({id: piece.id, published: piece.published});
        handleTogglePublished(piece);
      };
    //   const toggleShowDescriptionClick = (e:Event) => {
    //     setShowDescription(!showDescription);

    //     e.preventDefault();
    //     e.stopPropagation();
    //   };
    async function _handleDelete() {
        // console.log(handlePublishedToggle);
        // await mutation.mutateAsync({id: piece.id, published: piece.published});
        handleDelete(piece);
        // 关闭窗口
        closeModal();
      };

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="relative w-full md:w-[60%]
                                overflow-y-auto transform rounded-2xl p-6 bg-white
                                text-left shadow-xl transition-all flex flex-col gap-5">
                                    <div className="absolute top-2 right-2 z-10 w-fit p-2 bg-primary-blue-100 rounded-full flex flex-row gap-x-4">
                                        <button
                                            type="button"
                                            onClick={() => handleDownloadUrl(piece.url)}
                                            className="flex"
                                        >
                                            <DownloadIcon className="w-5 h-5 text-blue" />
                                        </button>

                                        <button
                                            type="button"
                                            onClick={() => handleCopyFromUrl(piece.url)}
                                            className="flex"
                                        >
                                            {isCopying ? (
                                                <LoaderCircle className="w-5 h-5 animate-spin text-zinc-400" />
                                                ) : (
                                                    <CopyIcon className="w-5 h-5 text-blue" />
                                                )
                                            }
                                        </button>

                                        <button
                                            type="button"
                                            onClick={closeModal}
                                            className="flex"
                                        >
                                            <X className="w-5 h-5 text-blue" />
                                        </button>
                                    </div>

                                    <div className="flex-1 flex flex-col w-full md:h-screen md:max-h-[90vh] gap-3 mt-4">
                                        <div className="relative bg-pattern bg-cover bg-center rounded-lg">
                                            <Image src={ piece.url } 
                                            alt="Car Image" 
                                            priority 
                                            width={500}
                                            height={0}
                                            className={`m-auto ${imgWidthClassName}`}
                                            onLoad={handleImageLoad}/>

                                            <div className="flex-1 flex flex-col gap-2 z-50 mb-2">
                                                {/* <button
                                                    onClick={(e) => toggleShowDescriptionClick(e)}
                                                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-4 py-2 rounded-md bg-blue-500 text-white">
                                                    {showDescription ? '隐藏' : '显示'}
                                                </button> */}
                                                <h2 className={`text-l capitalize p-4  bg-gray-300 bg-opacity-50`}>{ piece.title }</h2>
                                            </div>
                                            {
                                                userId === piece.userId ? (
                                                    <div className="flex-1 flex flex-row justify-between gap-2 z-50 mb-6">
                                                        <DeleteButton handleDelete={ _handleDelete } />
                                                        <PublishToggle isChecked={piece.published} handleToggle={ _handleTogglePublished } />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )
                                            }
                                        </div>

                                        {/* <div className="flex gap-3">
                                            <div className="flex-1 relative w-full h-24 bg-primary-blue-100 rounded-lg">
                                                <Image src={generateCarImageUrl(car,'29')} alt="Car Image" fill priority className="object-contain"/>
                                            </div>
                                            <div className="flex-1 relative w-full h-24 bg-primary-blue-100 rounded-lg">
                                                <Image src={generateCarImageUrl(car,'33')} alt="Car Image" fill priority className="object-contain"/>
                                            </div>
                                            <div className="flex-1 relative w-full h-24 bg-primary-blue-100 rounded-lg">
                                                <Image src={generateCarImageUrl(car,'13')} alt="Car Image" fill priority className="object-contain"/>
                                            </div>
                                        </div> */}
                                    </div>                           
                                </DialogPanel>
                            </TransitionChild>
                        </div>                         
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default PieceDetail;
