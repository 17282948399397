'use client';

import { useState, useEffect} from 'react';
import Image from "next/image";
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { PieceProps } from "@/types/piece";
import { getPagingPieces } from '@/app/(admin)/x/apis/pieces'
import PieceCard from "./PieceCard";
import ShowMore from '@/components/ShowMore';
import { toast } from 'sonner';
import { LoaderCircle } from 'lucide-react';

// 每行4个图片数据
const LIMIT = 20;

const PieceList = ({userId}: {userId: string}) => {
  const [pageNumber, setPageNumber] = useState<number>(0); 
  const [noMoreData, setNoMoreData] = useState(true); 
  const [moreLoading, setMoreLoading] = useState(false); 
  const [pagingPiecesData, setPagingPiecesData] = useState<PieceProps []>([]);

//   const queryClient = useQueryClient()

  const { isPending, isError, data } = useQuery({
    queryKey: ['pieces'],
    queryFn: async () => {
      return await getPagingPieces({offset: 0, limit: LIMIT})
    },
  });

  useEffect(() => {
    if (data === undefined) return;

    // console.log('又来了又来了又来了又来了又来了又来了');
    const list = data || [];
    if (list.length > 0) {
        // 可能会有部分数据重叠，需去掉
        // 每隔 ？ 秒？会自动执行useQuery获得data
        const distinctList = list.filter((item) => !pagingPiecesData.some((existItem) => existItem.id === item.id));
        if (distinctList && distinctList.length > 0) {
            // 加入头部，是最新的数据
            setPagingPiecesData([...distinctList, ...pagingPiecesData]);
        }
    }
    setNoMoreData(LIMIT > list.length);
  }, [data]);

  const mutation = useMutation({
    mutationFn: async ({p}: {p: number}) => {
      const res = await getPagingPieces({offset: p * LIMIT, limit: LIMIT})

      // if (res?.error) {
      //   throw new Error(JSON.stringify(res?.error))
      // }
      return res
    },
    onSuccess: (list) => {
        // const list = res || [];
      if (list.length > 0) {
        // 判断数据是否有重叠
        // 去掉重叠的数据，因为是点击更多按钮获得的数据，所以加到末尾
        const distinctList = list.filter((item) => !pagingPiecesData.some((existItem) => existItem.id === item.id));
        if (distinctList && distinctList.length > 0) {
            setPagingPiecesData([...pagingPiecesData, ...distinctList]);
            // 成功后加1
            setPageNumber(pageNumber + 1);
        }
      }
      setNoMoreData(LIMIT > list.length);
      setMoreLoading(false);
      // queryClient.invalidateQueries({
      //   queryKey: ['pieces'],
      // })
    },
    onError: (error) => {
        setMoreLoading(false);
      console.log(error);
      return toast.error('ERROR, Please try it again!');
    },
  });

//   const mutationPublished = useMutation({
//     mutationFn: async ({id, published}: {id: string, published: boolean}) => {
//       const res = await togglePiece({id: id, published: published})

//       if (res?.error) {
//         throw new Error(JSON.stringify(res?.error))
//       }
//       return res
//     },
//     onSuccess: (res) => {
//       queryClient.invalidateQueries({
//         queryKey: ['pieces'],
//       });
//     //   // 找到画面上的数据
//     //   const current = pagingPiecesData.find((v) => { return v.id === res.id })
//     //   if (current) {
//     //     current.published = res.published;        
//     //   }
//     },
//     onError: (error) => {
//       console.log(error);
//       return toast.error('ERROR, Please try it again!');
//     },
//   });  

//   const mutationDelete = useMutation({
//     mutationFn: async ({id, pathname}: {id: string, pathname: string}) => {
//       const res = await deletePiece({id: id})

//       if (res?.error) {
//         throw new Error(JSON.stringify(res?.error))
//       }

// 在该方法中无法获取process.env.bucket的值
//       await deleteImage(pathname);

//       return res
//     },
//     onSuccess: (res) => {
//       queryClient.invalidateQueries({
//         queryKey: ['pieces'],
//       });
//     },
//     onError: (error) => {
//       console.log(error);
//       return toast.error('ERROR, Please try it again!');
//     },
//   });    

//   useEffect(() => {
//     (async function run() {
//         await mutation.mutateAsync({p: pageNumber});  
//     })();
//   }, [pageNumber]);  

  const isDataEmpty = !Array.isArray(pagingPiecesData) || pagingPiecesData.length < 1 || !pagingPiecesData;

  const handlePaging = async () => {
    setMoreLoading(true);
    await mutation.mutateAsync({p: pageNumber + 1});  
  }

  const handleTogglePublished = async (piece: PieceProps) => {
    const published = piece.published;
    const index = pagingPiecesData.findIndex((v) => { return v.id === piece.id })
    if (index >= 0) {
        const list = pagingPiecesData.slice();

        const newPiece = list[index];
        newPiece.published = !published;

        setPagingPiecesData(list);
      }    
    // await mutationPublished.mutateAsync({id: piece.id, published: !published});  
    const res = await fetch('/api/piece/toggle', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: piece.id, 
          published: !published
        }),
      });
    const json = await res.json();
    if (res.ok) {
        toast.info(json.message);
    } else {
        toast.error(json.message);
    }    
  }  

  const handleDelete = async (piece: PieceProps) => {
    const index = pagingPiecesData.findIndex((v) => { return v.id === piece.id })
    if (index >= 0) {
        // 画面上先删除
        const list = pagingPiecesData.slice();
        list.splice(index, 1);
        setPagingPiecesData(list);
        // const pathname = piece.url.split('/').pop();
        // await mutationDelete.mutateAsync({id: piece.id, pathname: pathname!});  
        const res = await fetch('/api/piece/delete', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              id: piece.id, 
              url: piece.url
            }),
          });
        const json = await res.json();
        if (res.ok) {
            toast.info(json.message);
        } else {
            toast.error(json.message);
        }
    }
  }  

    return (
            !isDataEmpty ? (
                <section>
                    <div className="home__cars-wrapper">
                        {
                            pagingPiecesData?.map((piece) => <PieceCard key={piece.id} userId={userId} piece={piece} 
                            handleTogglePublished={ handleTogglePublished } handleDelete={ handleDelete }/>)
                        }
                    </div>

                    <ShowMore
                        loading={ moreLoading }
                        pageNumber={ pageNumber }
                        noMoreData={ noMoreData }
                        handlePaging={ handlePaging }
                    />

                </section>
            ) : (
                // <div className="home__error-container">
                //     <h2 className="text-black text-xl font-bold">Upps, no results</h2>
                //     <p>{publicPiecesData?.message}</p>
                // </div>
                <div className="w-full text-center gap-5 mt-10">
                    {
                    isPending && 
                    <div className="inline-flex items-center justify-between gap-2 text-sm py-2.5 px-6">
                        <LoaderCircle className="w-5 h-5 animate-spin text-zinc-400" />
                    </div>
                    }
                </div>
            )
    );
};

export default PieceList;
