"use client";

// import { useRouter } from "next/navigation";
import { ShowMoreProps } from "@/types/button";
import CustomButton from "@/components/CustomButton";
import { LoaderCircle } from 'lucide-react';

const ShowMore = ({loading, pageNumber, noMoreData, handlePaging}: ShowMoreProps) => {
    // const router = useRouter();

    // const handlePaging = () => {
    //     const newLimit = (pageNumber + 1) * 30;
    //     const newPathname = updateSearchParams("limit", `${newLimit}`);

    //     router.push(newPathname);
    // }

    return (
        <div className="w-full text-center gap-5 mt-10">
            {
                (!noMoreData && !loading) && (
                    <CustomButton
                        title="Show More"
                        btnType="button"
                        containerStyles="inline-flex items-center justify-between gap-2 text-white hover:text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-700 rounded font-black uppercase text-sm py-2.5 px-6"
                        textStyles=""
                        handleClick={handlePaging}
                    />
                )
            }
            {
                loading && (
                    <div className="inline-flex items-center justify-between gap-2 text-white hover:text-white bg-blue-600 hover:bg-blue-500 active:bg-blue-700 rounded font-black uppercase text-sm py-2.5 px-6">
                        <LoaderCircle className="w-5 h-5 animate-spin text-zinc-400" />
                    </div>
                )
            }
        </div>
    );
};

export default ShowMore;
