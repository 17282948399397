'use client';

import {useState} from 'react';
import CustomButton from "@/components/CustomButton";
import { LoaderCircle } from 'lucide-react';

const DeleteButton = ({handleDelete}: {handleDelete: () => void}) => {
  const [isConfirming, setIsConfirming] = useState(false);

  return (
    <div className="relative inline-flex items-center cursor-pointer select-none">
      { isConfirming  ? (
        <div className="relative inline-flex items-center cursor-pointer select-none gap-x-4">
          <CustomButton
              title="Cancel"
              btnType="button"
              containerStyles="inline-flex items-center justify-between gap-2 text-white hover:text-white bg-gray-600 hover:bg-gray-500 active:bg-gray-700 rounded font-black text-sm py-1.5 px-4"
              textStyles=""
              handleClick={() => setIsConfirming(false)}
          />
          <CustomButton
              title="Confirm"
              btnType="button"
              containerStyles="inline-flex items-center justify-between gap-2 text-white hover:text-white bg-red-600 hover:bg-red-500 active:bg-red-700 rounded font-black text-sm py-1.5 px-4"
              textStyles=""
              handleClick={handleDelete}
          />
        </div>
      ) : (
        <CustomButton
            title="Delete"
            btnType="button"
            containerStyles="inline-flex items-center justify-between gap-2 text-white hover:text-white bg-red-600 hover:bg-red-500 active:bg-red-700 rounded font-black text-sm py-1.5 px-4"
            textStyles=""
            handleClick={() => setIsConfirming(true)}
        />
      )
    }
    </div>
  );
}

export default DeleteButton;